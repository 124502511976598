import posthog from "posthog-js";

const analytic = (function () {
  var analytic = {};

  analytic.initialize = () => {
    posthog.init("phc_q6sEWJyoNsrCyJ0RxzObepPokJu3Yv9vd1OwkXhKnrX", {
      api_host: "https://analytic830rd.pmberjaya.com",
      disable_session_recording: true,
    });
  };

  analytic.track = (action, actionProperties = {}) => {
    posthog.capture(action, actionProperties);
  };

  analytic.get_distinct_id = () => {
    return posthog.get_distinct_id();
  };

  analytic.setUserId = (id = "0") => {
    posthog.identify(
      `${id}` // distinct_id, required
    );
  };

  analytic.reset = () => {
    posthog.reset();
  };

  return analytic;
})();

export default analytic;
