import { gql } from "@apollo/client";

export const LISTING_FRAGMENT = gql`
  fragment LandingListing on Listing {
    id
    listing_for
    category
    price
    product_name
    state {
      id: state_id
      state_name
    }
    area {
      id: area_id
      area_name
    }
    district {
      id: district_id
      district_name
    }
    cover_file {
      sizes {
        thumb
        small
      }
    }
    bedroom
    bathroom
    media {
      media_file {
        sizes {
          large
          small
        }
      }
    }
    user {
      id: user_id
      first_name
      last_name
      phone
      fcm_token
      user_level
      avatar_file {
        sizes {
          thumb
          small
        }
      }
    }
    date
  }
`;

export const GET_LISTING_RECOMMENDATION = gql`
  query getListingRecommendation(
    $page: Int!
    $distinct_id: String!
    $hasDistinctId: Boolean = true
  ) {
    recommendation {
      latest_items(top_n: 9) {
        ...LandingListing
      }
      listings(page: $page, limit: 9, distinct_id: $distinct_id)
        @include(if: $hasDistinctId) {
        nodes {
          ...LandingListing
        }
      }
      common_listing_recommendation_for_user(user_id: $distinct_id, top_n: 9)
        @include(if: $hasDistinctId) {
        ...LandingListing
      }
      user_location_based_recommendation(
        user_id: $distinct_id
        page: $page
        limit: 9
      ) @include(if: $hasDistinctId) {
        nodes {
          ...LandingListing
        }
      }
    }
    blog {
      posts(limit: 4) {
        id
        post_name
        post_title
        post_date
        post_author
        post_excerpt
        url
        attachment
        category
      }
    }
  }
  ${LISTING_FRAGMENT}
`;
