import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useCookie } from "next-cookie";
import dynamic from "next/dynamic";
import React from "react";
import { GET_USER_DATA } from "../action/user";

const ToastWrapper = dynamic(() => import("../component/common/Toast"), {
  ssr: false,
});

const StateContext = React.createContext(null);

const Provider = ({ children }) => {
  const cookie = useCookie();
  const token = cookie.get("token");
  const modalStatus = cookie.get("open_modal");
  const counts = cookie.get("count_view");

  const [state, setState] = React.useState({
    user: null,
    loginModal: null,
    type: "",
    property: "",
    minPrice: 0,
    maxPrice: 0,
    bed: 0,
    bath: 0,
    floor: 0,
    minBuild: 0,
    maxBuild: 0,
    districtName: "",
    stateName: "",
    countView: parseInt(counts) || 0,
  });

  const [getUserData, { data }] = useLazyQuery(GET_USER_DATA, {
    onError: () => {
      // router.push('/logout');
      setState({
        user: null,
        loginModal: null,
        type: "",
        property: "",
        minPrice: 0,
        maxPrice: 0,
        bed: 0,
        bath: 0,
        floor: 0,
        minBuild: 0,
        maxBuild: 0,
        districtName: "",
        stateName: "",
        areaName: "",
        countView: parseInt(counts) || 0,
      });
    },
  });

  useEffect(() => {
    if (data) {
      setState({
        ...state,
        user: data ? data.me : null,
      });

      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  useEffect(() => {
    if (modalStatus) {
      setState({ ...state, loginModal: modalStatus });
    }
  }, [modalStatus]);

  return (
    <StateContext.Provider value={[state, setState]}>
      {children}
      <ToastWrapper />
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return React.useContext(StateContext);
};

export default Provider;
