import { useCookie } from "next-cookie";
import { useMemo } from "react";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
    const cookie = useCookie();
    const token = cookie.get('token');


    if (token) {
        return {
            headers: {
                ...headers,
                authorization: token ?? "",
            }
        }
    }
    return { ...headers, }
});

let apolloClient;
let token = null;

export const clearToken = () => {
  token = null;
}

export function createApolloClient(headerOptions) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT}/query`,
      headers: {
        ...headerOptions,
      },
    })),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, headerOptions) {
  const _apolloClient = apolloClient ?? createApolloClient(headerOptions);

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}


export function createClientSideApolloClient(headerOptions) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_GRAPHQL_CLIENT_ENDPOINT}/query`,
      headers: {
        ...headerOptions,
      },
    })),
    cache: new InMemoryCache(),
  });
}

export function initializeClientSideApollo(initialState = null, headerOptions) {
  const _apolloClient = apolloClient ?? createClientSideApolloClient(headerOptions);

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState, headerOptions) {
  const store = useMemo(() => initializeClientSideApollo(initialState, headerOptions), [initialState]);
  return store;
}
